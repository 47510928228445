@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
.stripes {
  background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      rgba(255, 255, 255, 0.1) 10px,
      rgba(255, 255, 255, 0.1) 20px
    );
}
.dark-stripes {
  background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.1) 10px,
      rgba(0, 0, 0, 0.1) 20px
    );
}
.font-nav {
  font-family: "Roboto Condensed", sans-serif;
}
.font-brand {
  font-family: "AgencyFB-bold", sans-serif;
  letter-spacing: 0.1em;
}