/* windicss layer base */
@font-face {
  font-family: 'AgencyFB-bold';
  src: url('mythulu-core/assets/fonts/AgencyFB-Bold.ttf') format('truetype');
}
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #2563eb;
}
[type='checkbox'], [type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}
[type='checkbox']:focus, [type='radio']:focus {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #6b7280;
}
[type='checkbox']:checked, [type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type='checkbox']:checked:hover, [type='checkbox']:checked:focus, [type='radio']:checked:hover, [type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type='checkbox']:indeterminate:hover, [type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}
[type='checkbox'] {
  border-radius: 0px;
}
[type='radio'] {
  border-radius: 100%;
}
[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type='file']:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
button, select {
  text-transform: none;
}
button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}
button {
  background-color: transparent;
  background-image: none;
}
button, [role="button"] {
  cursor: pointer;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
h1, pre, p, h2, h3 {
  margin: 0;
}
h1, h2, h3 {
  font-size: inherit;
  font-weight: inherit;
}
img {
  border-style: solid;
  max-width: 100%;
  height: auto;
}
input::placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
img, svg {
  display: block;
  vertical-align: middle;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
pre, code {
  font-size: 1em;
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
textarea {
  resize: vertical;
}
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* windicss layer components */
.prose {
  color: #374151;
  max-width: 65ch;
  font-size: 1rem;
  line-height: 1.75;
}
.prose [class~="lead"] {
  color: #4b5563;
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.prose a {
  color: #111827;
  text-decoration: underline;
  font-weight: 500;
}
.prose strong {
  color: #111827;
  font-weight: 600;
}
.prose ol[type="A"] {
  --list-counter-style: upper-alpha;
}
.prose ol[type="a"] {
  --list-counter-style: lower-alpha;
}
.prose ol[type="A s"] {
  --list-counter-style: upper-alpha;
}
.prose ol[type="a s"] {
  --list-counter-style: lower-alpha;
}
.prose ol[type="I"] {
  --list-counter-style: upper-roman;
}
.prose ol[type="i"] {
  --list-counter-style: lower-roman;
}
.prose ol[type="I s"] {
  --list-counter-style: upper-roman;
}
.prose ol[type="i s"] {
  --list-counter-style: lower-roman;
}
.prose ol[type="1"] {
  --list-counter-style: decimal;
}
.prose ol > li {
  position: relative;
  padding-left: 1.75em;
}
.prose ol > li::before {
  content: counter(list-item, var(--list-counter-style, decimal)) ".";
  position: absolute;
  font-weight: 400;
  color: #6b7280;
  left: 0;
}
.prose ul > li {
  position: relative;
  padding-left: 1.75em;
}
.prose ul > li::before {
  content: "";
  position: absolute;
  background-color: #d1d5db;
  border-radius: 50%;
  width: 0.375em;
  height: 0.375em;
  top: calc(0.875em - 0.1875em);
  left: 0.25em;
}
.prose hr {
  border-color: #e5e7eb;
  margin-top: 3em;
  margin-bottom: 3em;
}
.prose blockquote {
  font-weight: 500;
  font-style: italic;
  color: #111827;
  border-left-width: 0.25rem;
  border-color: #e5e7eb;
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}
.prose blockquote p:first-of-type::before {
  content: open-quote;
}
.prose blockquote p:last-of-type::after {
  content: close-quote;
}
.prose h1 {
  color: #111827;
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.prose h2 {
  color: #111827;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.prose h3 {
  color: #111827;
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.prose h4 {
  color: #111827;
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.prose figure figcaption {
  color: #6b7280;
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.prose code {
  color: #111827;
  font-weight: 600;
  font-size: 0.875em;
}
.prose code::before {
  content: "`";
}
.prose code::after {
  content: "`";
}
.prose a code {
  color: #111827;
}
.prose pre {
  color: #e5e7eb;
  background-color: #1f2937;
  overflow-x: auto;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}
.prose pre code {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: 400;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.prose pre code::before {
  content: none;
}
.prose pre code::after {
  content: none;
}
.prose table {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.prose thead {
  color: #111827;
  font-weight: 600;
  border-bottom-width: 1px;
  border-bottom-color: #d1d5db;
}
.prose thead th {
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.prose tbody tr {
  border-bottom-width: 1px;
  border-bottom-color: #e5e7eb;
}
.prose tbody tr:last-child {
  border-bottom-width: 0;
}
.prose tbody td {
  vertical-align: top;
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.prose p {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.prose img {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose video {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose figure {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose h2 code {
  font-size: 0.875em;
}
.prose h3 code {
  font-size: 0.9em;
}
.prose ol {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  list-style-type: none;
}
.prose ul {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  list-style-type: none;
}
.prose li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.prose > ul > li p {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose > ul > li > *:first-child {
  margin-top: 1.25em;
}
.prose > ul > li > *:last-child {
  margin-bottom: 1.25em;
}
.prose > ol > li > *:first-child {
  margin-top: 1.25em;
}
.prose > ol > li > *:last-child {
  margin-bottom: 1.25em;
}
.prose ul ul, .prose ul ol, .prose ol ul, .prose ol ol {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose hr + * {
  margin-top: 0;
}
.prose h2 + * {
  margin-top: 0;
}
.prose h3 + * {
  margin-top: 0;
}
.prose h4 + * {
  margin-top: 0;
}
.prose thead th:first-child {
  padding-left: 0;
}
.prose thead th:last-child {
  padding-right: 0;
}
.prose tbody td:first-child {
  padding-left: 0;
}
.prose tbody td:last-child {
  padding-right: 0;
}
.prose > :first-child {
  margin-top: 0;
}
.prose > :last-child {
  margin-bottom: 0;
}
.prose-sm {
  font-size: 0.875rem;
  line-height: 1.7142857;
}
.prose-sm p {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm [class~="lead"] {
  font-size: 1.2857143em;
  line-height: 1.5555556;
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.prose-sm blockquote {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-left: 1.1111111em;
}
.prose-sm h1 {
  font-size: 2.1428571em;
  margin-top: 0;
  margin-bottom: 0.8em;
  line-height: 1.2;
}
.prose-sm h2 {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}
.prose-sm h3 {
  font-size: 1.2857143em;
  margin-top: 1.5555556em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}
.prose-sm h4 {
  margin-top: 1.4285714em;
  margin-bottom: 0.5714286em;
  line-height: 1.4285714;
}
.prose-sm img {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm video {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm figure {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm figure > * {
  margin-top: 0;
  margin-bottom: 0;
}
.prose-sm figure figcaption {
  font-size: 0.8571429em;
  line-height: 1.3333333;
  margin-top: 0.6666667em;
}
.prose-sm code {
  font-size: 0.8571429em;
}
.prose-sm h2 code {
  font-size: 0.9em;
}
.prose-sm h3 code {
  font-size: 0.8888889em;
}
.prose-sm pre {
  font-size: 0.8571429em;
  line-height: 1.6666667;
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  border-radius: 0.25rem;
  padding-top: 0.6666667em;
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.prose-sm ol {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm ul {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm li {
  margin-top: 0.2857143em;
  margin-bottom: 0.2857143em;
}
.prose-sm ol > li {
  padding-left: 1.5714286em;
}
.prose-sm ol > li::before {
  left: 0;
}
.prose-sm ul > li {
  padding-left: 1.5714286em;
}
.prose-sm ul > li::before {
  height: 0.3571429em;
  width: 0.3571429em;
  top: calc(0.8571429em - 0.1785714em);
  left: 0.2142857em;
}
.prose-sm > ul > li p {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.prose-sm > ul > li > *:first-child {
  margin-top: 1.1428571em;
}
.prose-sm > ul > li > *:last-child {
  margin-bottom: 1.1428571em;
}
.prose-sm > ol > li > *:first-child {
  margin-top: 1.1428571em;
}
.prose-sm > ol > li > *:last-child {
  margin-bottom: 1.1428571em;
}
.prose-sm ul ul, .prose-sm ul ol, .prose-sm ol ul, .prose-sm ol ol {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.prose-sm hr {
  margin-top: 2.8571429em;
  margin-bottom: 2.8571429em;
}
.prose-sm hr + * {
  margin-top: 0;
}
.prose-sm h2 + * {
  margin-top: 0;
}
.prose-sm h3 + * {
  margin-top: 0;
}
.prose-sm h4 + * {
  margin-top: 0;
}
.prose-sm table {
  font-size: 0.8571429em;
  line-height: 1.5;
}
.prose-sm thead th {
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.prose-sm thead th:first-child {
  padding-left: 0;
}
.prose-sm thead th:last-child {
  padding-right: 0;
}
.prose-sm tbody td {
  padding-top: 0.6666667em;
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}
.prose-sm tbody td:first-child {
  padding-left: 0;
}
.prose-sm tbody td:last-child {
  padding-right: 0;
}
.prose-sm > :first-child {
  margin-top: 0;
}
.prose-sm > :last-child {
  margin-bottom: 0;
}
/* windicss layer utilities */
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}
.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 105, 161, var(--tw-bg-opacity));
}
.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 18, 57, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.bg-slate-500\/50 {
  --tw-bg-opacity: 0.5;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
}
.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
}
.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}
.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
}
.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
}
.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}
.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.hover\:bg-slate-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}
.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgba(148, 163, 184, var(--tw-border-opacity));
}
.border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgba(15, 23, 42, var(--tw-border-opacity));
}
.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}
.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgba(51, 65, 85, var(--tw-border-opacity));
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.border {
  border-width: 1px;
}
.border-3 {
  border-width: 3px;
}
.border-1 {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.border-r {
  border-right-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.grid {
  display: -ms-grid;
  display: grid;
}
.hidden {
  display: none;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.self-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-serif {
  font-family: ui-serif,Georgia,Cambria,"Times New Roman",Times,serif;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.h-8 {
  height: 2rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.h-96 {
  height: 24rem;
}
.h-24 {
  height: 6rem;
}
.h-6 {
  height: 1.5rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-80 {
  height: 20rem;
}
.h-32 {
  height: 8rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.leading-tight {
  line-height: 1.25;
}
.m-auto {
  margin: auto;
}
.m-1 {
  margin: 0.25rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-0 {
  margin: 0px;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mt-\[-1em\] {
  margin-top: -1em;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.max-w-7xl {
  max-width: 80rem;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.p-3 {
  padding: 0.75rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-15 {
  padding: 3.75rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.right-0 {
  right: 0px;
}
.left-0 {
  left: 0px;
}
.top-0 {
  top: 0px;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0/0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0/0.1),0 8px 10px -6px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),0 8px 10px -6px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow-dark-500 {
  --tw-shadow-color: rgba(31, 31, 31, 1);
  --tw-shadow: var(--tw-shadow-colored);
}
.shadow-gray-200 {
  --tw-shadow-color: rgba(229, 231, 235, 1);
  --tw-shadow: var(--tw-shadow-colored);
}
.shadow-black {
  --tw-shadow-color: rgba(0, 0, 0, 1);
  --tw-shadow: var(--tw-shadow-colored);
}
.shadow-slate-900 {
  --tw-shadow-color: rgba(15, 23, 42, 1);
  --tw-shadow: var(--tw-shadow-colored);
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}
.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-opacity: 1;
  --tw-ring-offset-color: rgba(31, 41, 55, var(--tw-ring-offset-opacity));
}
.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}
.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus\:ring-amber-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
.fill-current {
  fill: currentColor;
}
.table-auto {
  table-layout: auto;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.focus\:text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}
.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-dark-500 {
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.text-dark-800 {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 24, var(--tw-text-opacity));
}
.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}
.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}
.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 63, 94, var(--tw-text-opacity));
}
.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}
.text-shadow {
  text-shadow: 0px 0px 1px rgb(0 0 0 / 20%), 0px 0px 1px rgb(1 0 5 / 10%);
}
.text-shadow-md {
  text-shadow: 0px 1px 2px rgb(30 29 39 / 19%), 1px 2px 4px rgb(54 64 147 / 18%);
}
.text-shadow-xl {
  text-shadow: 1px 1px 3px rgb(0 0 0 / 29%), 2px 4px 7px rgb(73 64 125 / 35%);
}
.italic {
  font-style: italic;
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.content-type {
  content: "type";
}
.w-8 {
  width: 2rem;
}
.w-48 {
  width: 12rem;
}
.w-full {
  width: 100%;
}
.w-4 {
  width: 1rem;
}
.w-10 {
  width: 2.5rem;
}
.w-5 {
  width: 1.25rem;
}
.w-screen {
  width: 100vw;
}
.w-16 {
  width: 4rem;
}
.w-6 {
  width: 1.5rem;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/2 {
  width: 50%;
}
.w-36 {
  width: 9rem;
}
.w-25 {
  width: 6.25rem;
}
.z-10 {
  z-index: 10;
}
.focus\:z-10:focus {
  z-index: 10;
}
.z-50 {
  z-index: 50;
}
.z-49 {
  z-index: 49;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}
.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.col-span-1 {
  -ms-grid-column-span: span 1 / span 1;
  grid-column: span 1 / span 1;
}
.col-span-2 {
  -ms-grid-column-span: span 2 / span 2;
  grid-column: span 2 / span 2;
}
.col-span-3 {
  -ms-grid-column-span: span 3 / span 3;
  grid-column: span 3 / span 3;
}
.col-span-4 {
  -ms-grid-column-span: span 4 / span 4;
  grid-column: span 4 / span 4;
}
.col-span-5 {
  -ms-grid-column-span: span 5 / span 5;
  grid-column: span 5 / span 5;
}
.col-span-6 {
  -ms-grid-column-span: span 6 / span 6;
  grid-column: span 6 / span 6;
}
.col-span-7 {
  -ms-grid-column-span: span 7 / span 7;
  grid-column: span 7 / span 7;
}
.col-span-8 {
  -ms-grid-column-span: span 8 / span 8;
  grid-column: span 8 / span 8;
}
.col-span-9 {
  -ms-grid-column-span: span 9 / span 9;
  grid-column: span 9 / span 9;
}
.col-span-10 {
  -ms-grid-column-span: span 10 / span 10;
  grid-column: span 10 / span 10;
}
.col-span-11 {
  -ms-grid-column-span: span 11 / span 11;
  grid-column: span 11 / span 11;
}
.col-span-12 {
  -ms-grid-column-span: span 12 / span 12;
  grid-column: span 12 / span 12;
}
.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}
.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}
.grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}
.grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}
.grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}
.grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}
.row-span-1 {
  -ms-grid-row-span: span 1 / span 1;
  grid-row: span 1 / span 1;
}
.row-span-2 {
  -ms-grid-row-span: span 2 / span 2;
  grid-row: span 2 / span 2;
}
.row-span-3 {
  -ms-grid-row-span: span 3 / span 3;
  grid-row: span 3 / span 3;
}
.row-span-4 {
  -ms-grid-row-span: span 4 / span 4;
  grid-row: span 4 / span 4;
}
.row-span-5 {
  -ms-grid-row-span: span 5 / span 5;
  grid-row: span 5 / span 5;
}
.row-span-6 {
  -ms-grid-row-span: span 6 / span 6;
  grid-row: span 6 / span 6;
}
.row-span-7 {
  -ms-grid-row-span: span 7 / span 7;
  grid-row: span 7 / span 7;
}
.row-span-8 {
  -ms-grid-row-span: span 8 / span 8;
  grid-row: span 8 / span 8;
}
.row-span-9 {
  -ms-grid-row-span: span 9 / span 9;
  grid-row: span 9 / span 9;
}
.row-span-10 {
  -ms-grid-row-span: span 10 / span 10;
  grid-row: span 10 / span 10;
}
.row-span-11 {
  -ms-grid-row-span: span 11 / span 11;
  grid-row: span 11 / span 11;
}
.row-span-12 {
  -ms-grid-row-span: span 12 / span 12;
  grid-row: span 12 / span 12;
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
}
.origin-top-right {
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}
.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  --tw-scale-z: .95;
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.duration-100 {
  -webkit-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms;
}
.duration-75 {
  -webkit-transition-duration: 75ms;
  -o-transition-duration: 75ms;
  transition-duration: 75ms;
}
.text-shadow-sm {
  text-shadow: 0 1px 1px var(--tw-shadow-color);
}
.text-shadow-md {
  text-shadow: 0 3px 5px var(--tw-shadow-color);
}
.text-shadow-lg {
  text-shadow: 0 5px 10px var(--tw-shadow-color);
}
.text-shadow-xl {
  text-shadow: 0 8px 16px var(--tw-shadow-color);
}
.text-shadow-none {
  text-shadow: none;
}
@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }
  .sm\:inline-block {
    display: inline-block;
  }
  .sm\:items-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
  .sm\:justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .sm\:h-12 {
    height: 3rem;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:ml-6 {
    margin-left: 1.5rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:pr-0 {
    padding-right: 0px;
  }
  .sm\:static {
    position: static;
  }
  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .sm\:w-screen-sm {
    width: 640px;
  }
}
@media (min-width: 768px) {
  .md\:inline-block {
    display: inline-block;
  }
  .md\:hidden {
    display: none;
  }
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
@media (min-width: 1024px) {
  .lg\:h-18 {
    height: 4.5rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg\:w-screen-lg {
    width: 1024px;
  }
}
@media (min-width: 1280px) {
  .xl\:w-screen-xl {
    width: 1280px;
  }
  .xl\:w-max-256 {
    width: 64rem;
  }
}
@media (prefers-color-scheme: dark) {
  .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .dark\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .dark\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .dark\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .dark\:bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
  }
  .dark\:bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(15, 23, 42, var(--tw-bg-opacity));
  }
  .dark\:bg-slate-500\/50 {
    --tw-bg-opacity: 0.5;
    background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
  }
  .dark\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .dark\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .dark\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .dark\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .dark\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .dark\:border-slate-700 {
    --tw-border-opacity: 1;
    border-color: rgba(51, 65, 85, var(--tw-border-opacity));
  }
  .dark\:border-slate-600 {
    --tw-border-opacity: 1;
    border-color: rgba(71, 85, 105, var(--tw-border-opacity));
  }
  .dark\:shadow-dark-800 {
    --tw-shadow-color: rgba(24, 24, 24, 1);
    --tw-shadow: var(--tw-shadow-colored);
  }
  .dark\:ring-offset-gray-700 {
    --tw-ring-offset-opacity: 1;
    --tw-ring-offset-color: rgba(55, 65, 81, var(--tw-ring-offset-opacity));
  }
  .dark\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-opacity: 1;
    --tw-ring-offset-color: rgba(55, 65, 81, var(--tw-ring-offset-opacity));
  }
  .dark\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .dark\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .dark\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .dark\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .dark\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .dark\:text-light-900 {
    --tw-text-opacity: 1;
    color: rgba(221, 225, 227, var(--tw-text-opacity));
  }
  .dark\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .dark\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .dark\:text-light-500 {
    --tw-text-opacity: 1;
    color: rgba(242, 242, 242, var(--tw-text-opacity));
  }
  .dark\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .dark\:text-light-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 252, 252, var(--tw-text-opacity));
  }
  .dark\:text-slate-100 {
    --tw-text-opacity: 1;
    color: rgba(241, 245, 249, var(--tw-text-opacity));
  }
}